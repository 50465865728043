:root {
  --black: #1C1A1F;
  --grey: #767676;
  --white: #FFF;
  --box-border: rgba(28, 26, 31, .1);
  --yellow: #FFE5A6;
  --light-blue: #E5EFF5;
}

html,
body {
  overflow-x: hidden;
}

.bg-white {
	background: url(/public/images/bg-white.jpg) var(--white);
	background-size: 150px 150px;
}
.bg-alt {
	background: url(/public/images/bg-alt.jpg) var(--white);
	background-size: 150px 150px;
}
.bg-yellow {
	background: url(/public/images/bg-yellow.jpg) var(--yellow);
	background-size: 150px 150px;
}
.bg-light-blue {
	background: url(/public/images/bg-light-blue.jpg) var(--light-blue);
	background-size: 150px 150px;
}

p a,
li a,
h4 a,
h6 a,
.text-link {
  color: var(--black);
  text-decoration: none;
  border-bottom: 1px solid;
  border-color: rgba(28, 26, 31, .2);
  transition: all .3s ease;
}
p a:hover,
li a:hover,
h4 a:hover,
h6 a:hover,
.text-link:hover {
  border-color: var(--black);
}
#footer h6 a {
  color: var(--white);
  border-color: rgba(255, 255, 255, .2);
}
#footer h6 a:hover {
  border-color: var(--white);
}

.icon-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	border-radius: 48px;
	text-indent: -9999px;
	background: center center no-repeat;
  border: none;
  transition: all .3s ease;
}
.icon-button:hover {
	background-color: rgba(28, 26, 31, 0.05);
}
.icon-button.dark-button:hover {
	background-color: rgba(255, 255, 255, 0.05);
}
.icon-button.icon-linkedin {
	background-image: url(/public/images/icon-linkedin.svg);
	background-size: 17px 16px;
}
.icon-button.icon-linkedin.dark-button {
	background-image: url(/public/images/icon-linkedin-white.svg);
	background-size: 17px 16px;
}
.icon-button.icon-crunchbase {
	background-image: url(/public/images/icon-crunchbase.svg);
	background-size: 23px 16px;
}
.icon-button.icon-x {
	background-image: url(/public/images/icon-x.svg);
	background-size: 16px 16px;
}
.icon-button.icon-x.dark-button {
	background-image: url(/public/images/icon-x-white.svg);
	background-size: 16px 16px;
}
.icon-button.icon-instagram {
	background-image: url(/public/images/icon-instagram.svg);
	background-size: 20px 16px;
}
.icon-button.icon-facebook {
	background-image: url(/public/images/icon-facebook.svg);
	background-size: 8px 16px;
}
.icon-button.icon-tiktok {
	background-image: url(/public/images/icon-tiktok.svg);
	background-size: 14px 16px;
}

.absolute-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.pattern {
	display: inherit;
	position: absolute;
	z-index: -1;
}

.logo-circle {
	height: 72px;
	width: 72px;
	object-fit: contain;
	border-radius: 72px;
}

.MuiTabs-flexContainer {
  overflow-x: scroll;
}

.scrollTo {
  scroll-margin-top: 112px;
}

.programCardTop:nth-of-type(1) {
  margin-top: 32px;
}



/* CARDS
-------------------------------------------------------------------------------------------*/
.card {
	border-radius: 16px;
	border: 1px solid var(--box-border);
	background: var(--white);
	color: var(--black);
	display: flex;
  transition: all .3s ease;
  text-decoration: none;
  margin-bottom: 16px;
}
.card-closed h4,
.card-closed p,
.page-closed h1,
.page-closed p {
  color: var(--grey);
  text-decoration: line-through;
}
.card-closed img,
.page-closed img {
  filter: grayscale(100%);
}
.card-closed .program-links {
  display: none;
}
.card:last-of-type {
  margin-bottom: 0;
}
.card:hover {
	box-shadow: 0 15px 10px -10px rgba(28, 26, 31, .3);
	transform: translate(0, -1px);
	border-color: rgba(28, 26, 31, .2);
}
.card-insight {
  flex-direction: row;
  gap: 24px;
}
.card-insight h2 {
  font-size: 22px;
  line-height: 32px;
  margin: 4px 0 12px 0;
}
.card-insight img {
  width: 120px;
  height: 120px;
}
.card-insight:nth-child(-n+2) {
  flex-direction: column;
  gap: 16px;
}
.card-insight:nth-child(-n+2) h2 {
  font-size: 37px;
  line-height: 48px;
}
.card-insight:nth-child(-n+2) img {
  width: 160px;
  height: 160px;
}
@media only screen and (max-width: 600px) {
  .card-insight {
    gap: 16px;
  }
  .card-insight:nth-child(-n+2) {
    flex-direction: row;
  }
  .card-insight img {
    width: 80px !important;
    height: 80px !important;
  }
  .card-insight h2,
  .card-insight:nth-child(-n+2) h2 {
    font-size: 22px;
    line-height: 32px;
  }
}
.card-container {
	border-radius: 8px;
	background: var(--white);
	display: flex;
  margin-bottom: 16px;
}
.card-container:last-of-type {
  margin-bottom: 0;
}



/* CHIP
-------------------------------------------------------------------------------------------*/
.MuiFormControlLabel-label {
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;
}
.MuiChip-label {
  padding: 0 !important;
}



/* INSIGHTS
-------------------------------------------------------------------------------------------*/
.insights-header a .MuiChip-root:hover {
  border-color: var(--black);
}
.article-body {
  max-width: 720px;
}
.article-body h4,
.article-body ul,
.article-body p,
.article-body li {
  margin: 0;
  padding: 0;
}
.article-body ul,
.article-body ol {
  margin: 0;
  padding: 8px 0 8px 20px;
}
.article-body li ul,
.article-body li ol {
  padding-top: 16px;
}
.article-body h4 {
  padding: 16px 0 8px 0;
}
.article-body p {
  padding-bottom: 24px;
}
.article-body li {
  padding-bottom: 16px;
}
.article-body blockquote {
  margin: 8px 0 32px 0;
  padding: 0 0 0 20px;
  border-left: 4px solid var(--yellow);
}
.article-body h2 {
  margin-top: 40px;
  padding: 0 0 0 20px;
  border-left: 4px solid var(--yellow);
}
.article-body blockquote p {
  padding: 0;
  font-style: italic;
}
.article-image {
  padding: 16px 0 40px 0;
  text-align: center;
}
.article-image img {
  width: 100%;
  max-width: 720px;
  height: auto;
}



/* CHARTS
-------------------------------------------------------------------------------------------*/
.MuiChartsAxis-line {
  stroke: var(--box-border) !important;
}
.MuiChartsAxis-tick {
  display: none;
}
.MuiChartsAxis-tickLabel {
  font-family: 'Roboto Mono';
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  text-transform: uppercase;
}



/* FORM FIELDS
-------------------------------------------------------------------------------------------*/
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--box-border) !important;
}
#footer .MuiOutlinedInput-root {
  width: 100%;
  border-radius: 8px 0 0 8px;
  height: 48px;
}
#footer .MuiFormControl-root {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .filter-fields .MuiTextField-root {
    width: 100%;
  }
}
.css-24rejj-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px 16px !important;
  height: auto !important;
  line-height: 24px !important;
}
.MuiFormGroup-root legend {
  font-weight: 600;
  margin-bottom: 4px;
}
.css-w3yfw9-MuiInputBase-root-MuiFilledInput-root.Mui-focused {
  background-color: var(--white) !important;
}
.css-w3yfw9-MuiInputBase-root-MuiFilledInput-root::after,
.css-w3yfw9-MuiInputBase-root-MuiFilledInput-root::before {
  border-color: var(--white) !important;
}
.css-w3yfw9-MuiInputBase-root-MuiFilledInput-root:hover {
  border-color: var(--box-border) !important;
}



/* ANIMATION
-------------------------------------------------------------------------------------------*/
@keyframes fadeIn {
  0% {
		opacity: 0;
  }
  100% {
		opacity: 1;
  }
}
@keyframes bottomToTop {
  0% {
		opacity: 0;
		-webkit-transform: translateY(10%);
  	transform: translateY(10%);
  }
  100% {
		opacity: 1;
		-webkit-transform: translateY(0);
  	transform: translateY(0);
  }
}
img, h1, h2, h3, h4, p, ul, ol, a, .MuiChip-root, Button, #top-nav, .filter-fields {
  animation: .5s fadeIn;
}
.card {
  animation: .5s bottomToTop;
}
